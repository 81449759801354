@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'League Spartan', -apple-system, sans-serif;
  background: url('../public/bgHero.png');
  background-repeat: no-repeat;
  background-position: right;
  scroll-behavior: smooth;
}



.logo {
  padding-top: 5rem;
}

.texto {
  padding-top: 2rem;
}

h1 {
  font-weight: 700;
  font-size: 3rem;
  width: 87%;
  color: #9F07FC;
}

h4 {
  width: 50%;
  font-size: 1.5rem;
  font-weight: 400;

}

h3 {
  color: #676767;
  font-weight: 600;
}


h5 {
  font-weight: 300;
  color: #A9A6A6;
  font-size: 1.3rem;
}

form {
  padding-top: 1rem;
}

form input {
  border: 1px solid #e6e6e6;
  padding: .5rem;
  width: 60%;
  color: #000;
  border-radius: 5px;
  outline-color: #9F07FC;
}

form button {
  padding: .5rem;
  width: 20%;
  border: 0;
  font-weight: 600;
  border-radius: 5px;
  color: #ffffff;
  background-color: #9F07FC;
  transition: ease-in .3s all;
}

form button:hover {
  opacity: .5;
}

::placeholder {
  opacity: .8;
}

.imagem {
  padding-top: 5rem;
}

.imagem img {
  width: 70%;
}

.botaoObrigado {
  background: #9F07FC;
  color: #ffffff;
  border: 0;
  padding: .6rem;
  margin-top: 3rem;
  border-radius: 5px;
  text-align: center;
  font-weight: 600;
  font-size: 1.2rem;
  width: 30%;
  transition: all ease-in .3s;
}

button:hover {
  opacity: .7;
}

@media screen and (max-width: 991px) {
  body {
    background: none;
    text-align: center;
  }

  h1 {
    width: 100%;
    margin: 0 auto;
    font-size: 2rem;
    text-align: left;
  }

  h3 {
    padding-top: 1rem;
    text-align: left;
  }

  h5 {
    text-align: left;
  }

  .logo {
    text-align: center;
  }

  .logo img {
    width: 40%;
  }

  form input {
    width: 100% !important;
  }

  form button {
    width: 100%;
  }

  .textoObrigado {
    text-align: center;
  }

  .textoObrigado h1 {
    width: 100%;
    text-align: center;
  }

  .textoObrigado h4 {
    text-align: center;
    width: 100%;
  }

  .botaoObrigado {
    width: 90%;
  }

}